import axios from "axios";
import { client } from "~/utils/axiosClient.util";
import {
  type ErrorResponse,
  IClinic,
  IClinicDetails,
  IClinicDetailsResponse,
  IClinicListResponse,
  IPrescriber,
  IPrescriberDetailsResponse,
  IUnverifiedClinicOrPrescriber,
  SuccessResponse,
} from "~/types";
import { useClinicsStore } from "~/stores/clinics.store";
import { useToastStore } from "~/stores/toast.store";

export async function loadAllClinics(): Promise<void> {
  const response = await client
    .get<IClinicListResponse>("/clinics")
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  const clinics = response.data.clinicList;
  useClinicsStore().storeClinics(clinics);
}

export async function loadClinicDetails(id: number): Promise<IClinicDetails> {
  const response = await client
    .get<IClinicDetailsResponse>(`/clinics/${id}`)
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  return response.data.clinicDetails;
}
export async function createClinic(clinic: IClinic): Promise<SuccessResponse> {
  const res = (await client.post(`/clinics`, clinic).catch((err) => {
    const { message } = err.response.data as ErrorResponse;
    message && useToastStore().showErrorToast("Error", message);
    return err.response;
  })) as SuccessResponse;
  await loadAllClinics();
  return res.data;
}

export async function uploadClinicLogoToS3(uploadURL: string, img: File) {
  await axios.put(uploadURL, img, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function createGP(gp: IPrescriber): Promise<void> {
  await client.post(`/prescribers`, gp).catch((err) => {
    const { message } = err.response.data as ErrorResponse;
    message && useToastStore().showErrorToast("Error", message);
    return err.response;
  });
}

export async function updateClinic(
  updatedClinic: any,
  clinic_id: string,
): Promise<SuccessResponse> {
  const res = await client
    .put<SuccessResponse>(`/clinics/${clinic_id}`, updatedClinic)
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  return res.data;
}

export async function getGPDetails(
  prescriber_id: number,
): Promise<IPrescriber> {
  const response = await client
    .get<IPrescriberDetailsResponse>(`/prescribers/${prescriber_id}`)
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  return response.data.prescriberDetails;
}

export async function updatePrescriber(
  updatedPrescriber: Partial<IPrescriber>,
  prescriber_id: number,
): Promise<SuccessResponse> {
  const res = await client
    .patch<SuccessResponse>(`/prescribers/${prescriber_id}`, updatedPrescriber)
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  return res.data;
}

export async function loadPrescriberDocURL(
  prescriber_id: number,
  document_id: number,
): Promise<string> {
  const response = await client
    .get<SuccessResponse>(
      `/prescribers/${prescriber_id}/doc_url/${document_id}`,
    )
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  return response.data.url;
}

export async function isUnverifiedPrescriberOrClinic(): Promise<boolean> {
  const response = await client
    .get<IUnverifiedClinicOrPrescriber>("/is_unverified")
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
  return response.data.isUnverified;
}

export async function verifyPrescriber(prescriber_id: number): Promise<void> {
  await client
    .get(`/prescribers/${prescriber_id}/verification/success`)
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
}

export async function verifyClinic(clinic_id: number): Promise<void> {
  await client
    .get(`/clinics/${clinic_id}/verification/success`)
    .catch((err) => {
      const { message } = err.response.data as ErrorResponse;
      message && useToastStore().showErrorToast("Error", message);
      return err.response;
    });
}
